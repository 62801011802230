import React from "react"

export default function getTime(date) {
  const utcDate = new Date(date + "Z")

  const localDateString = utcDate.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // true for 12-hour format
  })
  return <div>{localDateString}</div>
}
