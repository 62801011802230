import React, { useEffect, useState, useRef } from "react"
import { auth } from "../../firebase"
import { Formik, Form } from "formik"
import { useNavigate, Link } from "react-router-dom"
import axios from "axios"
import GLOBAL from "../../config/variables"
import { useUserContext } from "../../context/userContext"
import { LoaderMedium } from "../../components/Loader/loaders"
import * as Yup from "yup"
import ROUTES from "../../components/routesConstants"
//GA Tracking
import gaTracking from "../../Helpers/gaTracking"
import PatientDropdown from "./components/PatientDropdown"

import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { BreadCrumb } from "primereact/breadcrumb"
import { MultiSelect } from "primereact/multiselect"
import { Toast } from "primereact/toast"
import { Calendar } from "primereact/calendar"
import { InputNumber } from "primereact/inputnumber"

/** BreadCrums */
const home = {
  label: (
    <Link to={ROUTES.Home} style={{ textDecoration: "none", color: "inherit" }}>
      Home
    </Link>
  ),
}
const breadcrumbItems = [{ label: "New Order" }]

/**
 * Validation schema for the form using Yup.
 *
 * Validates the following fields:
 * - facility_id: Required, string.
 * - order_id: Required, string.
 * - patient_key: Required, string.
 * - order_datetime: Required, date, must not be in the future.
 * - clinical_conditions: Required, string.
 * - antigen_compatibility: Required if clinical_conditions is "SCD", must be a valid JSON array.
 */
const validationSchema = Yup.object().shape({
  facility_id: Yup.string().required("Facility is required"),
  order_id: Yup.string()
    .required("Order number is required")
    .matches(/^[^#?&/]*$/, "Order number cannot contain #, ?, &, or /"),
  patient_key: Yup.string().required("Patient is required"),
  order_datetime: Yup.date()
    .required("Order date is required")
    .test("is-not-in-future", "Order date cannot be in the future", (value) => {
      if (!value) return false
      const enteredDate = new Date(value).getTime()
      const now = new Date().getTime()
      return enteredDate <= now
    }),
  clinical_conditions: Yup.string().required("Clinical condition is required"),
  antigen_compatibility: Yup.string().test(
    "antigen-compatibility-required",
    "Antigen Negative Compatibility is required",
    function (value) {
      const { clinical_conditions } = this.parent

      if (!value && clinical_conditions === "SCD") {
        return false // Requiere un valor si la condición es SCD
      }

      if (value) {
        try {
          const parsedValue = JSON.parse(value)
          if (clinical_conditions === "SCD") {
            return Array.isArray(parsedValue) && parsedValue.length > 0 // Debe ser un array no vacío
          }
        } catch {
          return false // Formato JSON inválido
        }
      }

      return true // Todas las demás condiciones pasan
    }
  ),
  required_units: Yup.number()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string" && originalValue.trim() === "") {
        return 0
      }
      return value
    })
    .default(0)
    .typeError("Must be a number"),
})

const PertinentClinicalConditions = [
  { name: "Routine", code: "R" },
  { name: "Alloantibody", code: "AB" },
  { name: "Sickle Cell Disease", code: "SCD" },
]

/**
 * FormIndex is a component that renders a form to create a new order.
 * It utilizes React hooks to manage state and side effects for fetching
 * facilities, patients, and antigens. The component uses Formik for form
 * handling and validation, and provides a user interface to select a
 * facility, order number, patient ID, order date, clinical conditions,
 * and antigen compatibility. It handles form submission and navigates
 * to appropriate routes based on the server response.
 */
export default function FormIndex() {
  const toast = useRef(null)

  const navigate = useNavigate()
  const { user, token } = useUserContext()
  const [facilities, setFacilities] = useState([])
  const [patients, setPatients] = useState([])
  const [antigens, setAntigens] = useState([])

  const [selectedPatient, setSelectedPatient] = useState([])
  const [selectedCompatibility, setSelectedCompatibility] = useState(null)
  const [
    selectedPertinentClinicalConditions,
    setSelectedPertinentClinicalConditions,
  ] = useState(null)
  const [isAntigenDisabled, setIsAntigenDisabled] = useState(false)
  const [loadingAntigens, setLoadingAntigens] = useState(true)
  const [loadingFacilities, setLoadingFacilities] = useState(true)
  const [loadingPatients, setLoadingPatients] = useState(true)
  const [patientsFound, setPatientsFound] = useState(true)

  /**Google Analitics */

  const gaTrackingParams = {
    event: "",
    user,
    payload: {},
  }

  useEffect(() => {
    /**
     * Fetches patients from the server and updates the state accordingly
     */
    if (!token) return
    const fetchPatients = async () => {
      try {
        const response = await axios.get(
          `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/selector/patients`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.status === 200) {
          setPatients(response.data.response.data)
          if (response.data.response.data.length === 0) {
            console.log("No patients found")
            setPatientsFound(true)
          } else {
            console.log("Patients found")
            setPatientsFound(false)
          }
        }
      } catch (error) {
        console.error(
          "There is not a patient to fetch:",
          error,
          "If this is not what you expected, please contact support."
        )
      } finally {
        setLoadingPatients(false)
      }
    }
    fetchPatients()
  }, [token])

  useEffect(() => {
    /**
   * Fetches facilities from the server and updates the state accordingly
   *
   * When the response status is 200, the facilities are updated in the state.
   * If the response status is not 200, the user is redirected to the error page with the error message.
   * The loading state is set to false in both cases.
   *
 
   */ if (!token) return
    const fetchFacility = async () => {
      try {
        const response = await axios.get(
          `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/selector/facilities`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.status === 200) {
          setFacilities(response.data.response.data)
        }
        setLoadingFacilities(false)
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.warn("Invalid or expired token, redirecting to login page")
          auth.signOut().then(() => {
            navigate(ROUTES.Login, { replace: true })
          })
        } else {
          navigate(ROUTES.Error, {
            state: { error: error.message },
          })
        }
      }
    }
    fetchFacility()
  }, [token])

  useEffect(() => {
    /**
     * Fetches antigens from the server and updates the state accordingly
     *
     * When the response status is 200, the antigens are updated in the state.
     * If the response status is not 200, the user is redirected to the error page with the error message.
     * The loading state is set to false in both cases.
     *
     */
    const fetchAntigens = async () => {
      if (!token) return
      try {
        const response = await axios.get(
          `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/selector/antigens`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.status === 200) {
          setAntigens(response.data.response.data)
        }

        setLoadingAntigens(false)
      } catch (error) {
        navigate(ROUTES.Error, {
          state: { error: error.message },
        })
      }
    }
    fetchAntigens()
  }, [token])

  /**
   * Effect to set isAntigenDisabled to true if the selected
   * pertinent clinical condition is "R" and to false otherwise.
   * Additionally, it resets the selectedCompatibility state to an empty array
   * if isAntigenDisabled is set to true.
   */
  useEffect(() => {
    if (selectedPertinentClinicalConditions === "R") {
      setIsAntigenDisabled(true)
      setSelectedCompatibility([])
    } else {
      setIsAntigenDisabled(false)
    }
  }, [selectedPertinentClinicalConditions])

  if (loadingFacilities || loadingAntigens) {
    return (
      <div
        className="w-full h-screen flex justify-content-center align-items-center"
        style={{ paddingBottom: "10rem" }}
      >
        <LoaderMedium />
      </div>
    )
  }

  /**
   * Submits an order to the server with the provided values and handles the response.
   *
   * Converts the order date and time to UTC format and sets the loading state for antigens.
   * Sends a POST request to the server with the order details, including order ID, patient key,
   * facility ID, antigen compatibility, clinical conditions, and the formatted order date and time.
   * If the server response status is 200, navigates to different routes based on the order status
   * returned by the server. Handles various order statuses such as "MATCHED", "NEW", and "FAILED".
   * If no compatible blood units are found, navigates to an error page with a specific message.
   * In case of an error during the request, logs the error and navigates to an error page with the
   * error message from the server response.
   *
   * @param {Object} values - The order details including order_id, patient_key, facility_id,
   * antigen_compatibility, clinical_conditions, and order_datetime.
   */
  const handleSubmit = async (values) => {
    const utcDate = new Date(values.order_datetime)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ")

    setLoadingAntigens(true)

    try {
      const response = await axios.post(
        `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/`,
        {
          order_id: values.order_id,
          patient_key: values.patient_key,
          facility_id: values.facility_id,
          antigen_compatibility: values.antigen_compatibility,
          clinical_conditions: values.clinical_conditions,
          order_datetime: utcDate,
          required_units: values.required_units,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.status === 200) {
        if (response.data === "") {
          navigate(ROUTES.Error, {
            state: {
              error:
                "No compatible blood units found for this order. The order has been created with 'No Match' status.",
            },
          })
        } else {
          gaTrackingParams.event = "order_create"
          gaTrackingParams.payload = {
            order_id: values.order_id,
            order_status: response.data.response.data.order_status,
          }
          gaTracking(gaTrackingParams)

          console.log(response.data.response.data.order_status)
          switch (response.data.response.data.order_status) {
            case "MATCHED":
              navigate(`/order/${response.data.response.data.order_id}`)
              break
            case "NEW":
              navigate(ROUTES.OrderCompleted)
              break
            case "FAILED":
              navigate(ROUTES.FailedOrder)
              break
            default:
              navigate(ROUTES.Error, {
                state: { error: "Oops, something unexpected happened" },
              })
              break
          }
        }
      }
    } catch (error) {
      console.log(error)
      navigate(ROUTES.Error, {
        state: { error: error.response.data.response.message },
      })
    }
  }

  return (
    <div>
      <Toast ref={toast} />

      <BreadCrumb model={breadcrumbItems} home={home} />
      <div className="my-4">
        <h2 className="ml-4">Create New Order</h2>
      </div>
      <hr className="mx-4" />
      <section className="flex flex-column justify-content-center align-items-center">
        <Formik
          initialValues={{
            order_id: "",
            patient_key: "",
            facility_id: "",
            clinical_conditions: "",
            antigen_compatibility: "",
            order_datetime: "",
            required_units: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {(formik) => {
            return (
              <Form className="flex flex-column justify-content-center  gap-5 my-5">
                <div className="flex  align-items-center justify-content-between gap-4">
                  <label className="font-semibold" htmlFor="Facility">
                    Facility:
                  </label>
                  <div className="flex flex-column">
                    <Dropdown
                      options={facilities}
                      optionLabel="label"
                      optionValue="value"
                      filter
                      onChange={(e) => {
                        formik.setFieldValue("facility_id", e.value)
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.facility_id}
                      placeholder="Select a Facility"
                    />
                    {formik.touched.facility_id && formik.errors.facility_id ? (
                      <div className="p-error mt-2">
                        {formik.errors.facility_id}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex  align-items-center justify-content-between gap-4">
                  <label className="font-semibold" htmlFor="order_id">
                    Order Number:
                  </label>
                  <div className="flex flex-column">
                    <InputText
                      id="order_id"
                      name="order_id"
                      value={formik.values.order_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Order Number"
                    />
                    {formik.touched.order_id && formik.errors.order_id ? (
                      <div className="p-error mt-2">
                        {formik.errors.order_id}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex align-items-top justify-content-between gap-4">
                  <label className="font-semibold mt-3" htmlFor="patient_key">
                    Patient ID:
                  </label>
                  <PatientDropdown
                    patients={patients}
                    loadingPatients={loadingPatients}
                    selectedPatient={selectedPatient}
                    setSelectedPatient={setSelectedPatient}
                    formik={formik}
                  />
                </div>

                <div className="flex  align-items-center justify-content-between gap-4">
                  <label className="font-semibold" htmlFor="OrderDateTime">
                    Order Date Time:
                  </label>
                  <div className="flex flex-column">
                    <Calendar
                      id="OrderDateTime"
                      name="OrderDateTime"
                      value={formik.values.order_datetime}
                      onChange={(e) => {
                        formik.setFieldValue("order_datetime", e.value)
                      }}
                      onBlur={formik.handleBlur}
                      placeholder="Select a date"
                      showTime
                      minDate={new Date("2024-11-01T00:00:00")}
                      maxDate={new Date()}
                      hourFormat="12"
                      editableInput
                      showIcon
                    />

                    {formik.touched.order_datetime &&
                    formik.errors.order_datetime ? (
                      <div className="p-error mt-2">
                        {formik.errors.order_datetime}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="flex  align-items-center justify-content-between gap-4">
                  <label className="font-semibold" htmlFor="ClinicalConditions">
                    Pertinent Clinical Conditions:
                  </label>
                  <div className="flex flex-column">
                    <Dropdown
                      options={PertinentClinicalConditions}
                      optionLabel="name"
                      optionValue="code"
                      onChange={(e) => {
                        setSelectedPertinentClinicalConditions(e.value)
                        formik.setFieldValue("clinical_conditions", e.value)
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.clinical_conditions}
                      placeholder="Select a clinical condition"
                    />
                    {formik.touched.clinical_conditions &&
                    formik.errors.clinical_conditions ? (
                      <div className="p-error mt-2">
                        {formik.errors.clinical_conditions}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex  align-items-center justify-content-between gap-4">
                  <label className="font-semibold" htmlFor="Compatibility">
                    Antigen Negative Compatibility:
                  </label>
                  <div className="flex flex-column">
                    <MultiSelect
                      value={selectedCompatibility}
                      disabled={isAntigenDisabled}
                      onChange={(e) => {
                        setSelectedCompatibility(e.value)
                        const selectedAntigens = JSON.stringify(e.value)
                        formik.setFieldValue(
                          "antigen_compatibility",
                          selectedAntigens
                        )
                      }}
                      options={antigens}
                      optionLabel="label"
                      optionGroupLabel="label"
                      optionGroupChildren="items"
                      optionValue="value"
                      filter
                      placeholder="Select an antigen"
                    />
                    {formik.touched.antigen_compatibility &&
                    formik.errors.antigen_compatibility ? (
                      <div className="p-error mt-2">
                        {formik.errors.antigen_compatibility}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="flex  align-items-center justify-content-between gap-4">
                  <label className="font-semibold" htmlFor="Compatibility">
                    Required Units:
                  </label>
                  <div className="flex flex-column">
                    <InputNumber
                      id="RequiredUnits"
                      name="RequiredUnits"
                      onValueChange={(e) =>
                        formik.setFieldValue("required_units", e.value || 0)
                      }
                      value={formik.values.required_units}
                      placeholder="Enter Required Units"
                    />
                    {formik.touched.required_units &&
                    formik.errors.required_units ? (
                      <div className="p-error mt-2">
                        {formik.errors.required_units}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* /**
                 * Renders a conditional block of code based on the state of the patients,
                 * antigens and facilities loading states.
                 *
                 * If the patients are found, the antigens are loading, or the facilities are loading,
                 * the block of code is rendered.
                 */}
                {patientsFound || loadingAntigens || loadingFacilities ? (
                  <div className="flex justify-content-end">
                    <Button
                      label="Match"
                      type="submit"
                      className="w-3 p-button-primary"
                      disabled
                    />
                  </div>
                ) : (
                  <div className="flex justify-content-end">
                    <Button
                      label="Match"
                      type="submit"
                      className="w-3 p-button-primary"
                    />
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </section>
    </div>
  )
}
