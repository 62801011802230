const ROUTES = {
  Home: "/order-listing",
  Login: "/login",
  Form: "/form",
  Results: "/results",
  Confirmation: "/confirmation",
  Order: "/order/:id",
  OrderCompleted: "/order-completed",
  Error: "/error",
  FailedOrder: "/No-matched-order",
  SaveOder: "/saved",
}

export default ROUTES
