import React from "react"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import ROUTES from "../../../components/routesConstants"
import axios from "axios"
import GLOBAL from "../../../config/variables"
import { useUserContext } from "../../../context/userContext"

//GA Tracking
import gaTracking from "../../../Helpers/gaTracking"
export default function Buttons({ Orderstatus, OrderId, Orders }) {
  const navigate = useNavigate()
  const { user } = useUserContext()

  const selectedOrders = Orders.filter((order) => order.selected)

  /**GA Tracking */
  const gaTrackingParams = {
    event: "",
    user,
    payload: {},
  }

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Downloads a report of the selected orders in Excel format.
   */
  /******  ce1fc2bf-1b83-4016-9787-82a783f5c95c  *******/
  const handleDownload = async () => {
    try {
      axios
        .get(`${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/report/${OrderId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob", // response as blob
        })
        .then((res) => {
          /**GA Tracking */
          gaTrackingParams.event = "order_export"
          gaTrackingParams.payload = {
            order_id: OrderId,
          }
          gaTracking(gaTrackingParams)
          const contentDisposition = res.headers["content-disposition"]
          let fileName = "report.xlsx" //Default name

          if (contentDisposition) {
            const matches = contentDisposition.match(/filename="?(.+)"?/)
            if (matches && matches[1]) {
              fileName = matches[1]
            }
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
    } catch (error) {}
  }

  /**
   * Renders the buttons based on the order status.
   * Open and Matched orders have a "Confirm Selection" button and a "Save Selection" button.
   * Failed orders don't have any buttons.
   * All other orders have a "Download" button.
   */
  switch (Orderstatus) {
    case "OPEN":
    case "MATCHED":
      return (
        <div className="flex gap-5 justify-content-end m-4 align-items-center">
          <Button
            onClick={() => {
              handleDownload()
            }}
            className="p-button-secondary"
            label="Export as .XLSX"
          />

          <Button
            label="Confirm Selection"
            className="p-button-primary"
            onClick={() => {
              /**GA Tracking */
              gaTrackingParams.event = "order_confirm"
              gaTrackingParams.payload = {
                order_id: OrderId,
              }
              gaTracking(gaTrackingParams)

              const payload = {
                order_status: "CONFIRMED",
              }
              axios.put(
                `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/status/${OrderId}`,
                payload,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )

              navigate(ROUTES.Confirmation, {
                state: { orders: selectedOrders },
              })
            }}
          />
          <Button
            label="Save Selection"
            className="p-button-primary"
            onClick={() => {
              /**GA Tracking */
              gaTrackingParams.event = "order_save"
              gaTrackingParams.payload = {
                order_id: OrderId,
              }
              gaTracking(gaTrackingParams)

              const payload = {
                order_status: "OPEN",
              }
              axios.put(
                `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/status/${OrderId}`,
                payload,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )

              navigate(ROUTES.SaveOder, {
                state: { orders: selectedOrders },
              })
            }}
          />
        </div>
      )
    case "CONFIRMED":
    case "FAILED":
      return (
        <div className="flex gap-5 justify-content-end m-4 align-items-center">
          <Button
            onClick={() => {
              handleDownload()
            }}
            className="p-button-secondary"
            label="Export as .XLSX"
          />
        </div>
      )

    default:
      navigate(ROUTES.Error, {
        state: { error: "Something went wrong, please try again" },
      })
      return null
  }
}
