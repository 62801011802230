import React, { useEffect, useState } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import { useUserContext } from "../../context/userContext"
import { auth } from "../../firebase"
import ROUTES from "../../components/routesConstants"
import axios from "axios"
import GLOBAL from "../../config/variables"
import { LoaderMedium } from "../../components/Loader/loaders"

//Components
import Chart from "./Components/Chart"
import Buttons from "./Components/Buttons"
import Dashboard from "./Components/Dashboard"
//PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Checkbox } from "primereact/checkbox"
import { BreadCrumb } from "primereact/breadcrumb"
import { Calendar } from "primereact/calendar"

//Breadcrumb
const home = {
  label: (
    <Link to={ROUTES.Home} style={{ textDecoration: "none", color: "inherit" }}>
      Home
    </Link>
  ),
}

/**
 * Page that displays the results of an order, including a chart and a table
 * with the blood group units that match the order's criteria.
 */
export default function Results() {
  const { token } = useUserContext()
  const { id } = useParams()
  const [orders, setOrders] = useState({})
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const breadcrumbItems = [{ label: `Order Results: ${orders.order_id}` }]
  useEffect(() => {
    if (!id || !token) return

    /**
     * Fetches the order with the given id from the server and updates the state with the response data.
     * If the request fails, it navigates to the error page with the error message.
     * Finally, it sets the loading state to false.
     */
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        if (response.status === 200) {
          // Check if order_results is null or undefined and replace it with an empty array
          const orderResults = response.data.response.data.order_results || []

          setOrders({
            ...response.data.response.data,
            order_results: orderResults.map((order) => ({
              ...order,
              expiry_date: new Date(order.expiry_date), // change expiry_date to Date object
            })),
          })
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.warn("Invalid or expired token, redirecting to login page")
          auth.signOut().then(() => {
            navigate(ROUTES.Login, { replace: true })
          })
        } else {
          navigate(ROUTES.Error, {
            state: { error: error.message },
          })
        }
      } finally {
        setLoading(false)
      }
    }

    fetchOrders()
  }, [id, token])

  /**
   * Toggles the 'selected' state of a specific order result in the orders list
   * and updates the server with the new selection state.
   *
   * The function receives `rowData` as an argument, which contains the
   * `order_results_id` of the order result to be toggled. It updates the local
   * state by mapping through the `order_results` array and changing the `selected`
   * property of the matching order result to the opposite boolean value. It also
   * sends a PUT request to the server to update the selection state in the backend.
   *
   * @param {Object} rowData - The order result data, including `order_results_id`.
   */
  const toggleSelected = (rowData) => {
    setOrders((prevOrders) => ({
      ...prevOrders,
      order_results: prevOrders.order_results.map((result) =>
        result.order_results_id === rowData.order_results_id
          ? { ...result, selected: !result.selected }
          : result
      ),
    }))

    const payload = {
      order_result_id: `${rowData.order_results_id}`,
    }
    axios.put(
      `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/result/${rowData.order_results_id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  /**
   * Renders a calendar component for filtering dates in a data table.
   *
   * This function returns a Calendar component that allows users to select a date
   * for filtering purposes. The selected date is passed to a filter callback function
   * provided in the options parameter.
   *
   * @param {object} options - The options object containing the current filter value and
   *                           a callback function for updating the filter state.
   * @param {Date} options.value - The current value of the date filter.
   * @param {Function} options.filterCallback - The callback function to be invoked when
   *                                            the date filter changes. It receives the
   *                                            new date value and the index as parameters.
   * @returns {JSX.Element} - A Calendar component for selecting a date.
   */
  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)} // Asegúrate de pasar el valor correcto al callback
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
        showIcon
      />
    )
  }

  /**
   * A template function that renders a Date object as a string in the
   * format mm/dd/yyyy.
   *
   * @param {object} rowData - The data object containing the date to be
   *                          rendered.
   * @returns {string} - The rendered date string.
   */
  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.expiry_date)
  }

  /**
   * Formats a given Date object into a string with the format mm/dd/yyyy.
   *
   * @param {Date} value - The Date object to be formatted.
   * @returns {string} - The formatted date string.
   */
  const formatDate = (value) => {
    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  }

  /**
   * Renders a checkbox component that represents the selection state of an order result.
   *
   * The checkbox reflects whether the given order result is selected, and allows toggling
   * the selection state unless the order status is "CONFIRMED", in which case the checkbox
   * is disabled. The selection state is managed by calling the `toggleSelected` function
   * upon changes.
   *
   * @param {object} rowData - The data object containing the `selected` state and other
   *                           properties of the order result.
   * @returns {JSX.Element} - A JSX element rendering the selection checkbox.
   */
  const selectedBodyTemplate = (rowData) => {
    return (
      <Checkbox
        checked={rowData.selected}
        onChange={() => toggleSelected(rowData)}
        disabled={orders.order_status === "CONFIRMED"}
      />
    )
  }

  /**
   * A template function that renders a value representing the total number of
   * ABO/Rh antigen typings for a given order result.
   *
   * The value is the sum of the counts of the four ABO/Rh antigen typings. The
   * typings are represented by the following properties in the `rowData` object:
   * - `count_ag_duffy`
   * - `count_ag_cceekk`
   * - `count_ag_kidd`
   * - `count_ag_mns`
   *
   * @param {object} rowData - The data object containing the count properties of
   *                           the order result.
   * @returns {JSX.Element} - A JSX element rendering the total count of ABO/Rh
   *                           antigen typings.
   */
  const bpDataTemplate = (rowData) => {
    const bpData =
      rowData.count_ag_duffy +
      rowData.count_ag_cceekk +
      rowData.count_ag_kidd +
      rowData.count_ag_mns
    return (
      <div className="flex justify-content-center">
        <p>{bpData}</p>
      </div>
    )
  }

  const [filters, setFilters] = useState({
    global: { value: "", matchMode: "contains" },
    din_profile: { value: "", matchMode: "contains" },
    din: { value: "", matchMode: "contains" },
    aborh: { value: "", matchMode: "contains" },
    expiry_date: { value: null, matchMode: "custom" },
  })
  /**
   * Handles filter changes in the data table by updating the `filters` state with
   * the new filter values.
   *
   * @param {object} e - The event object containing the new filter values.
   * @param {object} e.filters - The new filter values.
   */
  const onFilterChange = (e) => {
    setFilters(e.filters)
  }

  if (loading) {
    return (
      <div
        className="w-full h-screen flex justify-content-center align-items-center"
        style={{ paddingBottom: "10rem" }}
      >
        <LoaderMedium />
      </div>
    )
  }

  // Sort the results to show selected at the beginning
  const sortedOrderResults = [...orders.order_results].sort((a, b) => {
    return (b.selected ? 1 : 0) - (a.selected ? 1 : 0)
  })

  return (
    <div>
      <BreadCrumb className="-mt-2" model={breadcrumbItems} home={home} />

      <Dashboard orders={orders} />
      {orders.order_results.length > 0 && <hr />}
      <section className="flex flex-column">
        {orders.order_results.length > 0 && (
          <div>
            <Chart
              orders={orders.order_results}
              onPointClick={(resultId) =>
                toggleSelected({ order_results_id: resultId })
              }
              orderInfo={orders}
            />
          </div>
        )}
      </section>
      {orders.order_results.length > 0 && <hr />}
      <section className="">
        <Buttons
          className="mx-4"
          Orderstatus={orders.order_status}
          OrderId={orders.order_id}
          Orders={orders.order_results}
        />

        <div className="my-4 p-0">
          <DataTable
            className="dataTable-Order mx-4"
            value={sortedOrderResults}
            paginator
            rows={100}
            filters={filters}
            onFilter={onFilterChange}
            globalFilterFields={["din_profile", "din", "aborh"]}
            emptyMessage="No blood group units available!"
          >
            <Column
              body={selectedBodyTemplate}
              style={{ textAlign: "center" }}
            />

            <Column
              className="w-30rem text-center"
              field="order_results_id"
              header="Result Id"
              sortable
            />
            <Column
              className="w-30rem"
              field="din"
              header="DIN"
              sortable
              filter
            />
            <Column
              className="w-30rem text-center"
              field="din_profile"
              header="Profile"
              sortable
              filter
            />
            <Column
              className="w-30rem text-center"
              field="aborh"
              header="ABO Rh"
              filter
              sortable
            />
            <Column
              className="w-30rem text-center"
              field="x"
              header="Safety Score"
              sortable
            />
            <Column
              className="w-30rem text-center"
              field="y"
              header="Replacement Score"
              sortable
            />
            <Column
              field="expiry_date"
              header="Expiration Date"
              sortable
              filter
              filterField="expiry_date"
              dataType="date"
              filterElement={dateFilterTemplate}
              body={dateBodyTemplate}
            />
            <Column
              className="w-30rem text-center"
              header="BP Count"
              body={bpDataTemplate}
              sortable
            />

            <Column
              className="w-30rem text-center"
              field="count_ag_extended"
              header="EP Count"
              sortable
            />
          </DataTable>
        </div>
      </section>
    </div>
  )
}
